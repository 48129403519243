<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">Caixa</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-7">
              <b-table
                :fields="[
                  'nome',
                  'tipo_produto',
                  'quantidade',
                  'valor',
                  'acoes',
                ]"
                :items="lista_pedido_produtos"
                :per-page="perPage"
                :current-page="currentPage"
                id="produto-table"
                class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-borderless
                "
                show-empty
                empty-text="Nenhum registro encontrado!"
              >
                <template #head(acoes)><span></span></template>
                <template #cell(acoes)="{ item }">
                  <div class="text-right w-100">
                    <button
                      @click="remove(item)"
                      class="btn btn-icon btn-light btn-sm mx-1"
                      v-b-tooltip.hover
                      title="remover"
                    >
                      <i class="fas fa-trash-alt text-danger"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="lista_pedido_produtos.length"
                :per-page="perPage"
                aria-controls="produto-table"
              >
              </b-pagination>
            </div>

            <div class="col-md-5">
              <div class="form-group sm row">
                <div class="col-md-12">
                  <label class="col-md-12">Produto:*</label>
                  <treeselect
                    :multiple="true"
                    :options="lista_produtos"
                    placeholder="Selecione os produtos..."
                    v-model="produto"
                  />
                </div>

                <div class="col-md-6">
                  <label class="col-md-12">Quantidade:*</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.quantidade"
                  />
                </div>
                <div class="col-md-6 text-center mt-8">
                  <button
                    class="btn btn-primary"
                    :disabled="verif"
                    @click="adicionarProdutos"
                  >
                    Adicionar
                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label class="col-md-12">Total:*</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="total"
                    disabled="true"
                  />
                </div>
                <div class="col-md-4 mt-8">
                  <!-- <button
                    @click.prevent="
                      confirm(!lista_campos.id ? 'cria' : 'edita')
                    "
                    class="btn btn-info"
                    :disabled="verif"
                  >
                    Finalizar
                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button> -->

                  <button
                    @click.prevent="showModalCaixa()"
                    class="btn btn-info"
                  >
                    Finalizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      id="modal-caixa"
      title="Finalização pedido"
      centered
      ok-only
      :ok-disabled="disableSaveModal"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-md-12">Forma de pagamento:*</label>
          <select
            v-model="formPedido.finan_forma_pagamento_id"
            class="form-control"
          >
            <option
              v-for="item in lista_finan_forma_pagamentos"
              :key="item.id"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-12">
          <label class="col-md-12">Moeda:*</label>
          <select v-model="formPedido.finan_moeda_id" class="form-control">
            <option
              v-for="item in lista_finan_moedas"
              :key="item.id"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <!-- <div class="form-group row">
          <div class="col-md-6">
            <label class="col-md-12">Emissao:*</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="emissao"
            />
          </div>
          <div class="col-md-6">
            <label class="col-md-12">Vencimento:*</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="data_validade"
            />
          </div>
        </div> -->
      </div>
      <button
        @click.prevent="confirm('criaPedido')"
        class="btn btn-info"
        v-if="lista_permissoes_filial.ra_PedidoEspera != null ? true : false"
      >
        Finalizar pedido
      </button>
      <button
        @click.prevent="confirm('finalizaDireto')"
        class="btn btn-info"
        v-if="lista_permissoes_filial.ra_PedidoEspera == null ? true : false"
      >
        Finalizar compra
      </button>
    </b-modal>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vendas" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        valor: "",
        quantidade: 1,
        desconto: 0,
      },
      formPedido: {
        finan_moeda_id: null,

        finan_forma_pagamento_id: null,
      },
      formCompra: {
        finan_forma_pagamento_id: null,
        finan_moeda_id: null,
      },
Vencimento:'',
emissao:'',
      total: 0,
      lista_pedido_produtos: [],
      produto: null,
      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
      currentPage: 1,
      perPage: 10,
      disableSaveModal: false,
    };
  },
  created() {
    this.listar_requerimentos();
  },

  computed: {
    lista_produtos() {
      let produtos = Object.values(this.$store.state.produto.lista_produtos);
      var data = [];

      for (let i = 0; i < produtos.length; i++) {
        data.push({ id: i, label: produtos[i].nome });
      }
      return data;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },

    lista_produtos_foradd() {
      return this.$store.state.produto.lista_produtos;
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },

    lista_finan_forma_pagamentos() {
      return this.$store.state.financeiro.lista_finan_forma_pagamentos;
    },

    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },

    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Produto no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "criaPedido") this.createPedido();
            if (tipo == "finalizaDireto") this.createFinalizacaCompra();
          }
        },
      });
    },
    async createPedido() {
      let formPedido = {
        pedido: this.formPedido,
        itens: this.lista_pedido_produtos,
        emissao: this.emissao,
        data_validade: this.data_validade,
      };

      // console.log( this.formPedido.itens)
      this.verif = true;
      await this.$store.dispatch(
        "financeiro/create_finan_pedido_recebimento",
        formPedido
      );
      this.verif = false;
      this.fireAlert(this.mensagem_alert);
      this.$bvModal.hide("modal-caixa");
      this.lista_pedido_produtos=[]
      this.total=0
      this.quantidade=1

    },
    // async createFinalizacaCompra() {
    //   let formPedido = {
    //     pedido: this.formPedido,
    //     itens: this.lista_pedido_produtos,
    //     emissao: this.emissao,
    //     data_validade: this.data_validade,
    //   };
    //   this.$store.dispatch("configEmpresa/MudarPreloader", true);
    //   this.verif = true;
    //   await this.$store.dispatch("financeiro/create_finan_pedido_recebimento",formPedido );
    //   this.verif = false;

    //   this.$store.dispatch("configEmpresa/MudarPreloader", false);
    //   this.fireAlert(this.mensagem_alert);
    // },

    async listar_requerimentos() {
      await this.$store.dispatch("produto/listar_produtos");
      await this.$store.dispatch("financeiro/listar_finan_moeda");
      await this.$store
        .dispatch("financeiro/listar_finan_forma_pagamento")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.preenxerCampos();
        });
    },
    async preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          produto: this.lista_campos.produto,

          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          user_in: this.lista_campos.user_in,
          user_up: this.lista_campos.user_up,
          filial_id: this.lista_campos.filial_id,
        };
    },
    adicionarProdutos() {
      var data = {};
      let quantidade = this.form.quantidade;
      var p = {};
      var count = 0;
      for (let i = 0; i < this.produto.length; i++) {
        p = this.lista_produtos_foradd[this.produto[i]];
        data = {
          id: p.id,
          nome: p.nome,
          tipo_produto: p.tipo_produto,
          quantidade: parseInt(quantidade),
          valor: p.valor,
          valor_desconto: this.form.desconto,
        };

        let line = this.lista_pedido_produtos;
        line.forEach(function (element, i) {
          if (element.id == data.id) {
            line[i].quantidade = element.quantidade + data.quantidade;
            count++;
          }
        });
        if (count < 1) {
          this.lista_pedido_produtos.push(data);
        }
      }
      this.produto = null;
      this.form.quantidade = 1;

      let total = 0;

      this.lista_pedido_produtos.forEach(function (element) {
        console.log(element.quantidade);
        total += element.valor * element.quantidade;
      });
      this.total = total;
    },
    remove(value) {
      this.lista_pedido_produtos = this.lista_pedido_produtos.filter(
        (array) => array.id !== value.id
      );
    },

    showModalCaixa() {
      this.$bvModal.show("modal-caixa");
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>